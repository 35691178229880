import { graphql } from 'gatsby';
import MentoringProgramPage from '../views/new-mentoring';

export default MentoringProgramPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
